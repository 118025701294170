import Swal from "sweetalert2";

interface AlertParams {
  title: string;
  text: string;
  action?: () => void;
}

interface ConfirmActionParams {
  title: string;
  text?: string;
  action: () => void;
  cancelAction?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  reverseButtons?: boolean; 
  customClass?: {
    confirmButton?: string;
    cancelButton?: string;
  };
}

interface ExecuteActionParams {
  actionText: string;
  actionFunction: () => void;
}

const getLoadingHtml = (action: string): string => {
  return `
      <div class="swal2-popup flex flex-col items-center justify-center !p-0">
        <span class="animate-spin border-8 border-[#f1f2f3] border-l-primary-second rounded-full w-[5em] h-[5em] inline-block align-middle m-auto mb-[1.25em]"></span>
        <h2 class="swal2-title">Carregando...</h2>
        <div id="swal2-html-container" class="swal2-html-container">${action}</div>
        <button type="button" class="btn btn-primary-second mt-[1.25em]" disabled>OK</button>          
      </div>
    `;
};

export const showSuccessAlert = ({
  title,
  text,
  action,
}: AlertParams): void => {
  Swal.fire({
    icon: "success",
    title: title,
    text: text,
    customClass: "sweet-alerts",
    willClose: () => action?.(),
  });
}

export const showInfoAlert = ({ title, text, action }: AlertParams): void => {
    Swal.fire({
      icon: 'info',
      title: title,
      text: text,
      customClass: 'sweet-alerts',
      willClose: () => action?.(),
    });
};

export const showErrorAlert = ({ title, text, action }: AlertParams): void => {
  Swal.fire({
    icon: "error",
    title: title,
    text: text,
    customClass: "sweet-alerts",
    willClose: () => action?.(),
  });
};

export const confirmAction = ({
  title,
  text,
  action,
  confirmButtonText = "Sim",
  cancelButtonText = "Não",
  showCancelButton = true,
  reverseButtons = true,
  ...params
}: ConfirmActionParams): void => {
  Swal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton,
    showConfirmButton: true,
    confirmButtonText,
    cancelButtonText,
    customClass: {
      cancelButton: "btn btn-outline-primary-second mt-2 pt-2 pr-4 pb-2 pl-4 min-w-[6rem]",
      confirmButton: "btn btn-primary-second mt-2 ml-2 pt-2 pr-4 pb-2 pl-4 min-w-[6rem]",
    },
    ...params,
    buttonsStyling: false,
    reverseButtons,
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

export const executeAction = ({
  actionText,
  actionFunction,
}: ExecuteActionParams): void => {
  Swal.fire({
    showConfirmButton: false,
    padding: "2em",
    html: getLoadingHtml(actionText),
    customClass: "sweet-alerts",
    didOpen: actionFunction,
  });
};

export const questionWarning = ({
  title,
  text,
  action,
  cancelAction,
  confirmButtonText = "Sim",
  cancelButtonText = "Não",
  showCancelButton = true,
  reverseButtons = true,
}: ConfirmActionParams): void => {
  Swal.fire({
    title,
    text,
    icon: "question",
    showCancelButton,
    reverseButtons,
    confirmButtonText,
    cancelButtonText,
    customClass: {
      cancelButton: "btn btn-outline-primary-second mt-2 pt-2 pr-4 pb-2 pl-4 min-w-[6rem]",
      confirmButton: "btn btn-primary-second mt-2 ml-2 pt-2 pr-4 pb-2 pl-4 min-w-[6rem]",
    },
    buttonsStyling: false,
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    } else if(result.dismiss === Swal.DismissReason.cancel) {
      cancelAction?.();
    }
  });
}