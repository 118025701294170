<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_922_980)">
            <path opacity="0.5"
                d="M1.66675 10.0001C1.66675 6.07171 1.66675 4.10752 2.88714 2.88714C4.10752 1.66675 6.07171 1.66675 10.0001 1.66675C13.9285 1.66675 15.8926 1.66675 17.113 2.88714C18.3334 4.10752 18.3334 6.07171 18.3334 10.0001"
                stroke="currentColor" stroke-width="1.5" />
            <path
                d="M1.66675 11.6667C1.66675 9.33311 1.66675 8.16634 2.12089 7.27504C2.52036 6.49103 3.15778 5.85361 3.94179 5.45414C4.83309 5 5.99986 5 8.33342 5H11.6667C14.0003 5 15.1671 5 16.0584 5.45414C16.8424 5.85361 17.4798 6.49103 17.8793 7.27504C18.3334 8.16634 18.3334 9.33311 18.3334 11.6667C18.3334 14.0002 18.3334 15.167 17.8793 16.0583C17.4798 16.8423 16.8424 17.4797 16.0584 17.8792C15.1671 18.3333 14.0003 18.3333 11.6667 18.3333H8.33341C5.99986 18.3333 4.83309 18.3333 3.94179 17.8792C3.15778 17.4797 2.52036 16.8423 2.12089 16.0583C1.66675 15.167 1.66675 14.0002 1.66675 11.6667Z"
                stroke="currentColor" stroke-width="1.5" />
            <path d="M9.99992 9.16675L9.99992 14.1667M9.99992 14.1667L11.6666 12.0834M9.99992 14.1667L8.33325 12.0834"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_922_980">
                <rect width="20" height="20" rx="5" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>