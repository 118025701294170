import './assets/css/app.css';  // Importação do CSS
import Chat from './components/chat.vue';
import ExecutionHistorical from './components/execution-historical.vue';
import FormDinamic from './components/form-dinamic.vue';
import PopUpInsertUpdate from './components/popup-insert-update.vue';
import PopUpLog from './components/popup-log.vue';
import Sidebar from './components/side-bar.vue';
import SimpleTimeline from './components/simple-timeline.vue';
import TimeLine from './components/time-line.vue';
import UiActionButtons from './components/ui-action-buttons.vue';
import UiFileUpload from './components/ui-fileupload.vue';
import UiTable from './components/ui-table.vue';
import { 
  showSuccessAlert, 
  confirmAction, 
  executeAction, 
  questionWarning, 
  showErrorAlert, 
  showInfoAlert 
} from './service/swal-messages';


export { 
  Sidebar, 
  ExecutionHistorical, 
  PopUpInsertUpdate, 
  Chat, 
  FormDinamic, 
  PopUpLog, 
  SimpleTimeline, 
  TimeLine, 
  UiActionButtons, 
  UiFileUpload, 
  UiTable,
  showSuccessAlert,
  confirmAction,
  executeAction,
  questionWarning,
  showErrorAlert,
  showInfoAlert,
};

export default {
  install(app) {
    app.component('execution-historical', ExecutionHistorical);
    app.component('popup-log', PopUpLog);
    app.component('chat', Chat);
    app.component('form-dinamic', FormDinamic);
    app.component('side-bar', Sidebar);
    app.component('popup-insert-update', PopUpInsertUpdate);
    app.component('simple-timeline', SimpleTimeline);
    app.component('time-line', TimeLine);
    app.component('ui-action-buttons', UiActionButtons);
    app.component('ui-fileupload', UiFileUpload);
    app.component('ui-table', UiTable);
  },
};
