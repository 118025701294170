<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3405 18.3333C14.9429 18.3333 18.6738 14.6023 18.6738 9.99996C18.6738 5.39759 14.9429 1.66663 10.3405 1.66663C5.73812 1.66663 2.00716 5.39759 2.00716 9.99996C2.00716 11.333 2.32017 12.593 2.87671 13.7104C3.02461 14.0073 3.07384 14.3467 2.98809 14.6671L2.49175 16.5222C2.27629 17.3274 3.01301 18.0642 3.81828 17.8487L5.67332 17.3524C5.99377 17.2666 6.33317 17.3158 6.6301 17.4637C7.74747 18.0203 9.00743 18.3333 10.3405 18.3333Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      opacity="0.5"
      d="M7.00781 8.75H13.6745"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      opacity="0.5"
      d="M7.00781 11.6666H11.5911"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
