import { gql } from "graphql-tag";

// Query para obter todos os riscos de ação
export const GET_ALL_ACTION_RISKS = gql`
  query getAllActionRisks {
    risk_management_risk {
      id
      name
      category {
        value
        id
        name_combo
      }
      description
      deadline_treatment
      id_responsible_area
      id_user_responsible
      id_user_appraiser
      user_appraiser {
        displayName
        id
      }
      user_responsible {
        displayName
        id
      }
      initial_risk
      state
      created_at
      log_risks(order_by: { id: asc }) {
        id
        date_time
        id_risk
        id_user
        log_description
        user {
          id
          displayName
          avatarUrl
          email
        }
      }
      risk_monitoring(order_by: { id: desc }) {
        residual_risk
        id_risk
        id
        justification
        review_deadline
        treatment_result {
          id
          active
          name_combo
          id_tenant
          value
        }
      }
    }
  }
`;
// Query para obter todos os usuários do módulo de privacidade (com base em seu status)
export const GET_ALL_USERS_OWNER = (id = null) => gql`
  query MyQuery($module: String! = "privacy") {
    user_module(
      order_by: { user: { displayName: asc } },
      where: {
        module: {
          module: { _eq: $module }
          modules_roles: { role: { _in: ["pr-respondent", "pr-manager", "pr-dpo", "tenant_admin_pr"] } }
        }
        _or: [
          {
            _and: [
              { blocked: { _eq: false } },
              { user: { disabled: { _eq: false } } }
            ]
          }
          ${id ? `, { user: { id: { _eq: "${id}" } } }` : ""}
        ]
      }
    ) {
      id
      blocked
      user {
        id
        displayName
        email
        disabled
        user_module_roles(where: { module: { module: { _eq: $module } } }) {
          role
          id
          id_module
        }
      }
    }
  }
`;

export const GET_RISK_BY_ID = gql`
  subscription getRiskById($id: Int!) {
    risk_management_risk_by_pk(id: $id) {
      category {
        value
        id
        name_combo
      }
      created_at
      description
      deadline_treatment
      id
      id_responsible_area
      id_tenant
      id_user_owner
      id_user_appraiser
      id_user_responsible
      user_appraiser {
        displayName
        id
        avatarUrl
      }
      user_responsible {
        displayName
        id
        avatarUrl
      }
      initial_risk
      name
      risk_assessment_txt
      state
      risk_tasks {
        id
        id_owner_task
        name_task
        state
      }
      log_risks(order_by: { id: asc }) {
        id
        date_time
        id_risk
        id_user
        log_description
        user {
          id
          displayName
          avatarUrl
          email
        }
      }
      area_module {
        id
        area {
          description
          id
        }
      }
      risk_monitoring(order_by: { id: desc }) {
        residual_risk
        id_risk
        id
        justification
        review_deadline
        treatment_result {
          id
          active
          name_combo
          id_tenant
          value
        }
      }
    }
  }
`;

export const GET_FILTERED_RISKS = gql`
  query GetFilteredRisks($where: risk_management_risk_bool_exp!) {
    risk_management_risk(where: $where, order_by: { created_at: desc }) {
      id
      name
      category {
        value
        id
        name_combo
      }
      deadline_treatment
      description
      initial_risk
      state
      created_at
      user_appraiser {
        id
        displayName
      }
      user_responsible {
        id
        displayName
      }
      risk_monitoring {
        id_risk
        review_deadline
        id_treatment_result
        justification
        residual_risk
        id
      }
    }
  }
`;

export const UPDATE_RISK_BY_ID = gql`
  mutation UpdateRisk(
    $id: Int!
    $obj_risk: risk_management_risk_set_input!
    $object_log: risk_management_log_risk_insert_input!
  ) {
    update_risk_management_risk_by_pk(
      pk_columns: { id: $id }
      _set: $obj_risk
    ) {
      id
      state
    }
    insert_risk_management_log_risk_one(object: $object_log) {
      id
    }
  }
`;

// Mutation para inserir um novo risco
export const INSERT_RISK = gql`
  mutation insertRisk($risk: risk_management_risk_insert_input!) {
    insert_risk_management_risk_one(object: $risk) {
      area_module {
        area {
          area_modules {
            id
          }
          id
          description
        }
      }
      category {
        active
        value
        id
        name_combo
      }
      description
      id
      id_responsible_area
      id_user_responsible
      id_user_owner
      name
      initial_risk
      user_responsible {
        id
        displayName
      }
      user_appraiser {
        displayName
        id
      }
    }
  }
`;

export const SEND_EMAIL_TREATMENT_FINISH = gql`
  mutation MyMutation($id_risk: Int!) {
    rmSendEmailToNotifyAppraiserOnTreatmentFinish(id_risk: $id_risk) {
      error
      success
    }
  }
`;

export const SEND_EMAIL_TREATMENT_REPROVE = gql`
  mutation MyMutation($id_risk: Int!) {
    rmSendEmailToNotifyResponsibleOnTreatmentReprove(id_risk: $id_risk) {
      error
      success
    }
  }
`;
