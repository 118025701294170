import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { nhost } from "../main";
import { isUserBlocked } from "@/service/blocking";
import { updateRole, role } from "@/service/roles";
import { checkRouteAccess } from "@/service/checkRouteAccess";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: (to) => {
      return { name: "home" };
    },
  },
  {
    path: "/",
    meta: {
      requiresAuth: true,
      requiresPickedTenant: true
    },
    children: [
      {
        path: "access-denied-m",
        name: "access-denied-m",
        component: () => import("@/components/errors/AccessDeniedM.vue"),
      },
      {
        path: "access-denied",
        name: "access-denied",
        component: () => import("@/components/errors/AccessDenied.vue"),
      },
      {
        path: "page-not-found",
        name: "page-not-found",
        component: () => import("@/components/errors/PageNotFound.vue"),
      },
      {
        path:"/",
        children: [
          {
            path: "home",
            name: "home",
            component: () => import("@/views/Home.vue"),
            meta: { breadcrumb: "HOME"},
          },
          {
            path: "instructions-for-use",
            name: "instructions-for-use",
            component: () => import("@/views/InstructionsForUse.vue"),
            meta: { breadcrumb: "Instruções de uso"},
          },
          {
            meta: {
              allowedRoles: ["tenant_admin_rm", "tenant_admin_rm_bay"],
            },
            path: "config",
            children: [
              {
                path: "",
                name: "config",
                component: () => import("@/views/ConfigDash.vue"),
                meta: { breadcrumb: "Configurações" },
              },
              {
                path: ":table",
                name: "config-table",
                component: () => import("@/views/ConfigTable.vue"),
              },
            ],
          },
          {
            path: "risk/:cod",
            name: "risk",
            component: () => import("@/views/Risk.vue"),
          },
          {
            path: "risk/:cod/task/:id",
            name: "task",
            component: () => import("@/views/Task.vue"),
          },
        ]
      },     
    ],
  },
  // Qualquer outra rota redireciona para "page-not-found"
  {
    path: "/:catchAll(.*)",
    redirect: "/page-not-found",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  //await nhost.auth.refreshSession();
  const requiresAuth = to.meta.requiresAuth;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  nhost.auth.refreshSession();
  // Atualizar sessão antes de verificar autenticação e tenant
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");
  
  const role = await updateRole();
  
  // Verificação de autenticação
  if (requiresAuth && !isAuthenticated) {
    window.location.href = "/auth#/login";
    return;
  }
  
  
  
  // Redirecionamento se o tenant for obrigatório mas não estiver selecionado
  if (requiresPickedTenant && !hasPickedTenant) {
    const encodedRedirect = encodeURIComponent(
      `/modules/gestao-de-riscos#${to.fullPath as string}`
    );
    window.location.href = `/home#/setup/setup-choice${encodedRedirect}`;
    return;
  }

  // Verificação de bloqueio de usuário
  if ((await isUserBlocked()) && to.fullPath !== "/access-denied-m") {
    next({ name: "access-denied-m" });
    return;
  }

  // Verificação de roles permitidas
  if (
    allowedRoles &&
    !allowedRoles.includes(role) &&
    to.fullPath !== "/access-denied"
  ) {
    next({ name: "access-denied" });
    return;
  }

  // Verificação de acesso ao risco específico
  if (role === "rm-respondent") {
    if (to.name === "risk" && to.params.cod) {
      const hasAccess = await checkRouteAccess(to.params.cod as string);
      if (!hasAccess) {
        next({ name: "access-denied" });
        return;
      }
    }
  }

  next();
});

export default router;
