// @/service/riskAccess.js
import { apolloClient } from "@/main";
import { gql } from "graphql-tag";
import { nhost } from "@/main";

// Função para verificar se o usuário tem acesso com base na condição "_and"
export async function checkRouteAccess(riskId) {
  const userId = nhost.auth.getUser()?.id;
  const tenantId = nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  if (!userId || !tenantId) {
    return false;
  }

  try {
    const { data } = await apolloClient.query({
      query: gql`
        query CheckRiskAccess($riskId: Int!, $userId: uuid!, $tenantId: uuid!) {
          risk_management_risk(
            where: {
              _and: [
                { risk_tasks: { id_owner_task: { _eq: $userId } } }
                { id: { _eq: $riskId } }
                { id_tenant: { _eq: $tenantId } }
              ]
            }
          ) {
            id
          }
        }
      `,
      variables: { riskId, userId, tenantId },
      fetchPolicy: "no-cache",
    });

    return data.risk_management_risk.length > 0;
  } catch (error) {
    console.error("Erro ao verificar acesso ao risco:", error);
    return false;
  }
}
