import { gql } from "graphql-tag";

export const SELECT_AREA = gql`
  query SelectArea($module: String!) {
    area_modules(
      where: { module: { module: { _eq: $module } } }
      order_by: { area: { description: asc } }
    ) {
      id
      status
      area {
        id
        description
        status
      }
    }
  }
`;

export const SELECT_AREA_ACTIVE = gql`
  query SelectArea {
    area(where: { status: { _eq: "A" } }, order_by: { description: asc }) {
      id
      description
      status
      area_modules {
        id
        id_area
      }
    }
  }
`;

export const INSERT_AREA = gql`
  mutation InsertNewArea($objects: [area_insert_input!]!) {
    insert_area(objects: $objects) {
      returning {
        id
        description
        status
        id_tenant
      }
    }
  }
`;

export const INSERT_AREA_MODULES = gql`
  mutation MyMutation($object: area_modules_insert_input!) {
    insert_area_modules_one(object: $object) {
      id
      id_area
      area {
        description
        status
        id
        id_tenant
      }
    }
  }
`;

export const UPDATE_AREA = gql`
  mutation UpdateArea(
    $id: Int!
    $status: bpchar!
    $id_area: Int!
    $blocked: Boolean = false
  ) {
    update_area_modules_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
      area {
        id
        description
      }
    }
    update_user_area(
      where: { id_area: { _eq: $id_area } }
      _set: { blocked: $blocked }
    ) {
      affected_rows
    }
    update_area(where: { id: { _eq: $id_area } }, _set: { status: $status }) {
      returning {
        id
        description
      }
    }
  }
`;
