<template>
   <div class="panel w-full" v-if="loading">
      <component :is="Skeleton" :class="`!h-[24px] !w-[26rem] mb-[10px]`"/>
      <component :is="Skeleton" :class="`!h-[24px] !w-[30rem] mb-[10px]`" />
      <div class="flex gap-2">
        <component :is="Skeleton" v-for="i in 8" :class="`!h-[129px] gap-2`" :key="i"/>
      </div>
  </div>
  <div class="panel w-full" v-else>
    <div class="flex flex-col items-start m-0 h-[70px]">
      <slot name="header"/>
    </div>
    <div
      class="h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b] mb-5"
    ></div>

    <!-- timeline with wizard component from vristo  -->
    <div x-data="{ activeTab: 1 }">
      <div class="inline-block w-full">
        <div class="relative z-[1]  overflow-x-auto">
          <!-- timeline line content  -->      
          <!-- timeline steps content  -->
          <ul class="flex w-full">
            <li
              v-for="(item, index) in timelineSteps"
              :key="index"
              class="flex flex-col items-center"
              :class="{ 'text-dark-second': activeTab === item.id }"
            >
            <div class="flex w-full">
              <div v-if="index > 0" :class="`${item.completed ?'bg-primary-second' : 'bg-white-light'} drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] h-1 w-[80px] relative top-[32px] ml-[-23px] shadow-lg left-0 -z-[1] transition-[width]`" ></div>
                <div :class="`flex flex-col items-center w-[98px] ${index > 0 && 'ml-[-22px]'}`">
                <a
                  href="javascript:;"
                  class="cursor-default border-[3px] m-2 mx-0 border-[#f3f2ee]  shadow-[0_1px_3px_0_rgba(0,0,0,0.25)] bg-white flex justify-center items-center w-16 h-16 rounded-full"
                  :class="{
                    '!border-primary-second !bg-primary-second text-white':
                      activeTab === item.id,
                    '!border-primary-second !bg-primary-second text-white': item.completed,
                  }"
                >
                  <component :is="item.icon" :class="`w-6 h-6 ${item.completed ? 'invert brightness-0' : 'filter brightness-50 contrast-200'}`" />
                </a>
                  <span
                    :class="`${ item.completed && 'text-primary' } drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] text-center max-w-[100px] block mt-2`"
                    >{{ item.label }}</span
                  >
                </div>
            </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>
<script lang="ts">
import { defineComponent, ComponentPublicInstance } from "vue";
import Skeleton from 'primevue/skeleton';

type timeLine = {
  id: number,
  label: string,
  icon: ComponentPublicInstance,
  completed: boolean
};


export default defineComponent({
  name: "TimeLine",
  props: {
    data: {
      type: Object as () => timeLine,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { Skeleton };
  },
  data() {
    return {
      activeTab: null as number | null,
      timelineSteps: null,
    };
  },
  mounted() {
    this.loadData(this.data);
  },
  watch: {
    data(newVal, oldVal){
      this.loadData(newVal);
    }
  },
  methods: {
    loadData(row){
      if(!row) return;
      this.timelineSteps = row;
    }
  }
});
</script>

