<template>
  <div class="panel flex flex-col mb-[-1vh] !sticky max-h-[calc(99vh-64px)] top-0 w-fit">
    <div @click="toggleMenu()"
      :class="`flex ${openMenu ? 'pb-[1.2rem] pt-[1.2rem]' : 'flex-col pb-[0.2rem] pt-[0.2rem]'} justify-center  items-center cursor-pointer ease-in-out transition-all delay-[30ms] gap-2 mb-2`">
      <component :is="IconPen" class="w-[28px] h-[28px]" />
      <h3 v-if="openMenu" class="text-lg text-dark font-normal m-0" >{{ headerTitle }}</h3>
      <p v-else class="text-[14px] text-dark font-normal m-0">Ações</p>
    </div>
    <div class="h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b] mb-5"></div>
    <div v-if="!loading" class="flex flex-col gap-4">
      <div class="flex flex-col gap-4" v-if="buttons?.some((item)=> item?.show)">
        <button v-for="button in buttons?.filter((item)=> item?.show)" v-tooltip:top="!openMenu && button.label" :key="button.key" type="button" @click="handleShowAction(button)"
          :class="`btn btn-${button.color} ${openMenu ? 'w-64' : 'w-12'} ease-in-out transition-all delay-[30ms] gap-2 font-semibold`">
          <component :is="button.icon" class=" shrink-0" />
          <span :class="`${openMenu ? 'block' : 'hidden'}`">{{ button.label }}</span>
        </button>
      </div>
      <slot name="empty" v-else>
        <Button         
          v-tooltip:top="`Atualmente você não possui ações pendentes a serem realizadas neste menu`" 
          type="button" 
          :disabled="true" 
          unstyled
          :class="`btn btn-[#000] !border-[2px] transition-all !shadow-none delay-[30ms] gap-2 font-semibold ${openMenu ? 'w-64' : 'w-12'}`">
          <component :is="IconCircleNode" class=" shrink-0" />
          <span :class="`${openMenu ? 'block' : 'hidden'}`">Sem ações neste menu</span>
        </Button>
      </slot>
    </div>
    <div v-else class="grid gap-4">
      <component :is="Skeleton" v-for="i in 5" :class="`!h-[32px] gap-2 ${openMenu ? '!w-64' : '!w-12'}`" :key="i"/>
    </div>
    <button type="button" @click="backButtonFunction"
      :class="`btn btn-info ${openMenu ? 'w-64' : 'w-12'} ease-in-out transition-all delay-[30ms] gap-2 mt-auto font-semibold`">
      <component :is="IconBack" class=" shrink-0" />
      <span :class="`${openMenu ? 'block' : 'hidden'}`">Voltar</span>
    </button>
  </div>
  <Dialog :draggable="false" :header="actionSelected.title" :class="actionSelected.colorTitle" modal v-model:visible="showActionModal" >
       <div :class="`${actionSelected?.widthForm ? `w-[${actionSelected?.widthForm}]` : 'min-w-[650px] max-w-[1000px]' }`">
        <component ref="formComponent" 
        @submitted="actionSelected.click"
        :is="actionSelected.content" />
       </div>
        
    <template #footer>
         <component :is="Button"
           type="button"
           class="btn-trustech btn-outline-primary-second"
           @click="showActionModal = false"
           label="Cancelar"
           unstyled
         />
         <component :is="Button"
           :form="$refs.formComponent?.form?.id"
           type="submit"
           class="btn-trustech btn-primary-second"
           label="Salvar"
           unstyled
         />
    </template>
  </Dialog>

</template>
<script lang="ts">
import { ref, computed } from "vue";
import IconBack from "./icon/icon-undo.vue";
import Button from "primevue/button";
import IconPen from "./icon/icon-pen.vue";
import Skeleton from 'primevue/skeleton';
import IconCircleNode from "./icon/icon-circle-node.vue";



type ButtonA = {
  key: string;
  label: string;
  color: string;  
  icon: any;
  content?: any;
  widthForm?: string;
  colorTitle?: string;
  clickBefore?: (action: Function) => void;
  click: () => void;
};

export default {
  name: "UiActionButtons",
  props: {
    buttons: {
      type: Array<ButtonA>,
      required: true,
    },
    headerTitle: {
      type: String,
      default: "Menu de Ações", 
    },
    backButtonFunction: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if(window.innerWidth > 1400){
      this.openMenu = true;
    }
  },
  setup() {
    return { Button, IconBack, IconPen, Skeleton, IconCircleNode };
  },
  data() {
    return {
      showActionModal: ref(false),
      isLoading: ref(false),
      openMenu: ref(false),
      actionSelected: ref({} as ButtonA)      
    }
  },
  methods: {
    toggleMenu(){
      this.openMenu = !this.openMenu;
    },
    handleShowAction(button: ButtonA) {
      if(!button?.content){       
        return button.click();
      }
      if(button?.clickBefore){
        button.clickBefore(() => {
          this.actionSelected = button;
          this.showActionModal = true;
        })
      }
      else {
        this.actionSelected = button;
        this.showActionModal = true;
      }
    }
  }
}

</script>
