<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1617_9851)">
        <circle opacity="0.5" cx="9.16666" cy="9.1665" r="7.5" stroke="currentColor" stroke-width="1.5"/>
        <path d="M18.1767 17.4791C18.1244 17.558 18.03 17.6525 17.8412 17.8413C17.6523 18.0302 17.5579 18.1246 17.479 18.1768C17.0168 18.4829 16.3916 18.3251 16.1299 17.8365C16.0853 17.7531 16.0469 17.6252 15.9703 17.3693C15.8865 17.0898 15.8446 16.95 15.8365 16.8517C15.7889 16.2725 16.2724 15.789 16.8515 15.8367C16.9499 15.8448 17.0896 15.8867 17.3692 15.9704C17.625 16.0471 17.7529 16.0854 17.8363 16.1301C18.325 16.3918 18.4827 17.017 18.1767 17.4791Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_1617_9851">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>        
</template>
