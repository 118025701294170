<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
    x="4"
      y="4"
      width="12"
      height="12"
      transform="rotate(45 10 10)"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>