<template>
  <div
    class="flex flex-col items-center whitespace-pre justify-center w-full gap-4 h-auto border-t-[1px]"
  >
  <div v-if="loading" :class="'w-full border overflow-y-auto pt-4 pb-4 ' + this.class">
      <div class="sm:flex pr-4 pl-4" v-for="i in 8" :key="i">
        <div class="relative mx-auto  sm:mb-0 z-[2]"
          :class="i !== 8 ? 'before:absolute before:top-12 before:left-1/2 before:-bottom-[15px] before:-translate-x-1/2 before:border-l-2 before:border-[#ebedf2] before:w-0 before:h-auto before:-z-[1] before:hidden sm:before:block' : ''">
          <component :is="Skeleton" shape="circle" size="3rem" class="mx-auto" />
        </div>
        <div class="flex-1">
          <div class="ml-4 mb-[30px] ">
            <component :is="Skeleton" width="215px" height="17px" class="mb-2" />
            <component :is="Skeleton" width="190px" height="17px" />
          </div>

          <div class="ml-4 mb-16">
            <div class="whitespace-pre flex mt-4 sm:mt-7 ">
              <component :is="Skeleton" width="280px" height="17px" />
            </div>
            <component :is="Skeleton" width="350px" height="140px" class="whitespace-pre mt-[18px]" />
          </div>
        </div>
      </div>
    </div>
    <div :class="'w-full overflow-y-auto ' + this.class" v-else>
      <div class="sm:flex ml-4 marker: p-4 whitespace-pre-line" v-for="item in timeline?.value" :key="item?.id">
        <div
          :class="'relative mx-auto mb-5 sm:mb-0 z-[2] before:absolute before:top-12 before:left-1/2 before:-bottom-[15px] before:-translate-x-1/2 before:border-l-2 before:border-[#ebedf2] before:w-0 before:h-auto before:-z-[1] before:hidden sm:before:block'"
        >        
          <img
            :src="item?.user?.avatarUrl || userAvatar"
            alt="image"
            class="w-12 h-12 mx-auto object-cover rounded-full shadow-[0_4px_9px_0_rgba(31,45,61,0.31)]"
          />
        </div>
        <div class="flex-1">
          <div class="ml-4">
            <h4 class="text-primary-second m-0 text-xl font-bold text-left">
              {{ item?.user?.displayName }}
            </h4>
            <p class="m-0">{{ item?.created_at }}</p>
          </div>
          <div class="ml-4 mb-16">
            <div
              class="flex items-center mt-4 sm:mt-7 text-[16px] font-bold text-black/80"
            >
              <component
                :is="item?.icon"
                class="icon-historic inline-block mr-2 w-5 h-5"
              />
              {{ item?.action }}
            </div>
            <p class="m-2" style="word-wrap: anywhere">{{ item?.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, ComponentPublicInstance } from "vue";
import userAvatar from "../assets/images/avatar.svg";
import Skeleton from "primevue/skeleton";

type Timeline = {
  id: number;
  description: string;
  action: string;
  icon: ComponentPublicInstance;
  created_at: string;
  user: {
    displayName: string;
    email: string;
    avatarUrl: string;
  };
};

export default {
  name: "ExecutionHistorical",
  props: {
    data: {
      type: Array as () => Timeline[],
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    class: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(){
    return {
      Skeleton,
    }
  },
  data() {
    return{
        timeline: ref([]),
        userAvatar,
    }
  },
  watch: {
    data(newValue) {
      this.loadData(newValue) 
    },
  },
  mounted() {
    this.loadData(this.data);
  },
  methods: {
    loadData(row){
      if(!row) return;
      this.timeline.value = row
    },
  },
};
</script>

<style>
.icon-historic{
  filter: brightness(0) invert(0);
}
</style>