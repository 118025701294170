<template>
  <div :class="`w-full ${!isValid ? 'invalid-component': ''}`" >
    <component :is="FileUpload" @removeUploadedFile="removeUploadedFile($event)" @select="uploadDocument"
        ref="attach_doc_evidence" name="demo[]" :multiple="true" :auto="true" :showUploadButton="false"
        :showCancelButton="false" id="ui-file-upload" accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.gif,.mp4,.avi,.mkv,.wmv" :maxFileSize="200000000">
      
      <template #header="{ chooseCallback, files }">
        <div class="flex flex-wrap justify-between align-center flex-1 gap-2">
          <div class="flex gap-2 ">
            <component :is="Button" :loading="loading" type="button" class="btn-trustech btn-primary-second" @click="chooseCallback()">
              <component :is="IconExport" class="w-5 h-5" />
              Anexar
            </component>
          </div>
          <component :is="ProgressBar" :value="totalSizePercent" :showValue="false" id="ui-file-upload-progress-bar"
            :class="['h-auto w-full md:ml-auto p-0 m-0', { 'exceeded-progress-bar': totalSizePercent > 100 }]">
            <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
          </component>
        </div>
      </template>

      <!-- Custom content template -->
      <template #content="{ files, uploadedFiles, removeFileCallback, removeUploadedFileCallback, progress, messages }">
          <div class="p-message p-component p-message-error">
              <div class="p-message-wrapper" v-for="(message, i) in messages" :key="i">
                  <component :is="IconCloseCircle" class="p-icon p-message-icon" />
                  <div class="p-message-text p-message-text">
                      {{ message }}
                  </div>
                  <component :is="Button" class="p-message-close p-link" icon="pi pi-times" @click="messages.splice(i, 1);"/>
              </div>     
          </div>   
          <div v-if="uploadedFiles.length > 0" class="p-fileupload-file" v-for="(file, index) in uploadedFiles" :key="index">
                <img
                    v-if="handleSelectFileIcon(file.name)"
                    :src="handleSelectFileIcon(file.name).iconClass"
                    :style="handleSelectFileIcon(file.name).style"
                />
                <div class="p-fileupload-file-details">
                      <div class="p-fileupload-file-name">{{ file.name }}</div>
                      <span class="p-fileupload-file-size">{{ formatSize(file.size) }}</span>
                      <span class="p-badge p-component p-badge-success p-fileupload-file-badge">{{ progress === 0 ? 'Concluído' : 'Pendente' }}</span>
                </div>
                <div class="p-fileupload-file-actions">
                   <button type="button" class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text p-fileupload-file-remove" @click="removeUploadedFileCallback(index)">
                       <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon p-button-icon" aria-hidden="true"><path d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z" fill="currentColor"></path></svg>          
                   </button>
                </div>
          </div>
      </template>

      <template #empty>
        <div class="flex gap-8 w-full items-end justify-center">
          <img src="../assets/images/file-preview.png" alt="file preview empty feedback" class="w-16 object-contain" />
          <img src="../assets/images/file-preview.png" alt="file preview empty feedback" class="w-24 object-contain" />
        </div>
      </template>
      
    </component>
  </div>
</template>

<script>
import { ref } from 'vue';
import { handleSelectFileIcon } from '../service/evidence-icons';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import IconExport from './icon/icon-export.vue';
import IconCloseCircle from './icon/icon-close-circle.vue';

export default {   
  name: 'UiFileUpload',
  emits: ['update:modelValue'],
  props: {
    isValid: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    return { 
      IconExport,
      IconCloseCircle,
      ProgressBar,
      Button,
      FileUpload
    };
  },
  data() {
    return {
      totalSize: 0,
      totalSizePercent: 50,
      files: [],
      modalValue: [],
      handleSelectFileIcon,
      loading: false,
    };
  },
  methods: {
    formatSize(bytes) {
     if (bytes === 0) return '0 Bytes';
     
     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
     const i = Math.floor(Math.log(bytes) / Math.log(1024));
     
     // Limitar a 2 casas decimais
     const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);
     
     return `${formattedSize} ${sizes[i]}`;
    },
    removeUploadedFile(event) {  
      this.modelValue = {...this.$refs.attach_doc_evidence.uploadedFiles};
      this.$emit('update:modelValue', this.modelValue?.[0] ? this.modelValue : '');
    },
    async uploadDocument(file) {
      const OldLength = this.$refs.attach_doc_evidence.uploadedFiles?.length;
      this.loading = true;
      if(!this.$refs.attach_doc_evidence.messages.length){
        while(this.$refs.attach_doc_evidence.uploadedFiles?.length != OldLength + file?.files?.length){
          await new Promise((r) => setTimeout(r, 100))
        }
      }
      this.loading = false;
      //hide loading
      this.modelValue = this.$refs.attach_doc_evidence.uploadedFiles;           
      this.$emit('update:modelValue', this.modelValue);
    },
    onRemoveTemplatingFile(file, removeFileCallback, index) {
      removeFileCallback(index);
      this.totalSize -= parseInt(this.formattedSize(file.size));
      this.totalSizePercent = this.totalSize / 10;
    },

    onClearTemplatingUpload(clear) {
      clear();
      this.totalSize = 0;
      this.totalSizePercent = 0;
    },

    onSelectedFiles(event) {
      this.files = event.files;
      this.files.forEach((file) => {
        this.totalSize += parseInt(this.formattedSize(file.size));
      });
    },

    uploadEvent(callback) {
      this.totalSizePercent = this.totalSize / 10;
      callback();
    },
  },
};
</script>


<style lang="scss">
#ui-file-upload .p-fileupload-buttonbar {
    background-color: #ffff;
    padding-inline: 0;
    padding-bottom: 0;
    @apply border;
}

#ui-file-upload .p-fileupload-buttonbar>div:first-child {
    width: 100%;

    div:first-child {
        margin-left: 1rem;
    }
}

#ui-file-upload .p-fileupload-content {
    max-height: 140px !important;
    overflow-y: scroll !important;
    @apply border;
    border-top: none;
}

// progress bar do template content
#ui-file-upload .p-progressbar-determinate {
    display: none;
}

#ui-file-upload .p-progressbar-value {
    margin-left: 0 !important;
}

// progress bar do header 
#ui-file-upload #ui-file-upload-progress-bar {
    display: block;
    margin-top: .5rem;
}

.invalid-component #ui-file-upload {
    .p-fileupload-buttonbar, .p-fileupload-content{
      border-color: red;
      border-bottom-color: red;      
    }
    .p-fileupload-buttonbar{
        border-bottom-color: #e5e7eb;
    }
}
 
#ui-file-upload {
    .p-fileupload-file{
	width: 99%
}
.p-fileupload-file-thumbnail{
	max-width: 50%
}
.p-fileupload-file-details {
  width: 70%;
}

.p-fileupload-file-name {
  font-size: 90%;
}
.p-fileupload-file-size {
  font-size: 70%;
}

.p-badge.p-component.p-badge-success.p-fileupload-file-badge {
  font-size: 65%;
  height: rem;
}

.p-fileupload-file-actions {
  width: 1%;
}

}
</style>