<template>
  <Dialog
    v-model:visible="popVisible"
    ref="popup_insert_update"
    :style="{ width: widthPopUp || '450px' }"
    :modal="true"
    :header="popUpValues?.title"
    :draggable="false"
  >
    <component :is="formDinamic"
      ref="forms_cadup_popup"
      :form="FormsPopUp"
      @submitted="valuesEmited"
      @changed="valuesChanged"
      @editableValue="valuesEditable"
    />
      <template #footer>
         <component :is="Button"
           type="button"
           class="btn-trustech btn-outline-primary-second"
           @click="popVisible = false"
           label="Cancelar"
           unstyled
         />
         <component :is="Button"
           @click="submitForm()"
           class="btn-trustech btn-primary-second"
           label="Salvar"
           unstyled
         />
    </template>
  </Dialog>
</template>
<script>
import formDinamic from './form-dinamic.vue';
import Button from 'primevue/button';

export default {
  name: "PopUpInsertUpdate",
  props: {
    popUpValues: Object,
    widthPopUp: String,
  },
  emits: ["PopUpValuesEmited", "PopUpValuesFormChanged", "PopUpeditableValue"],
  setup() {
    return {
      formDinamic,
      Button
    };
  },
  data() {
    return {
      popVisible: false,
      FormsPopUp: {},
    };
  },
  methods: {
    submitForm() {
      this.$refs.forms_cadup_popup.formsRef.handleSubmit();
    },
    async valuesEmited(values) {
      this.$emit("PopUpValuesEmited", values);
    },
    async valuesChanged(values) {
      this.$emit("PopUpValuesFormChanged", values);
    },
    valuesEditable(values) {
      this.$emit("PopUpeditableValue", values);
    },
  },
};
</script>
