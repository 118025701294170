import pdfIcon from "../assets/images/c-manage/fa-file-pdf.svg";
import imageIcon from "../assets/images/c-manage/fa-file-image.svg";
import wordIcon from "../assets/images/c-manage/fa-file-word.svg";
import excelIcon from "../assets/images/c-manage/fa-file-excel.svg";
import powerpointIcon from "../assets/images/c-manage/fa-file-powerpoint.svg";
import textIcon from "../assets/images/c-manage/fa-file-lines.svg";
import videoIcon from "../assets/images/c-manage/fa-file-video.svg";
import defaultIcon from "../assets/images/c-manage/fa-file.svg";

const iconEvidenceConditions = {
  ".pdf": {
    iconClass: pdfIcon,
    style: { width: "36px", color: "#ce0d10" },
  },
  ".jpg": {
    iconClass: imageIcon,
    style: { width: "36px", color: "#181898" },
  },
  ".jpeg": {
    iconClass: imageIcon,
    style: { width: "36px", color: "#181898" },
  },
  ".png": {
    iconClass: imageIcon,
    style: { width: "36px", color: "#181898" },
  },
  ".gif": {
    iconClass: imageIcon,
    style: { width: "36px", color: "#181898" },
  },
  ".doc": {
    iconClass: wordIcon,
    style: { width: "36px", color: "#074fb1" },
  },
  ".docx": {
    iconClass: wordIcon,
    style: { width: "36px", color: "#074fb1" },
  },
  ".xls": {
    iconClass: excelIcon,
    style: { width: "36px", color: "#277649" },
  },
  ".xlsx": {
    iconClass: excelIcon,
    style: { width: "36px", color: "#277649" },
  },
  ".ppt": {
    iconClass: powerpointIcon,
    style: { width: "36px", color: "#d6572e" },
  },
  ".pptx": {
    iconClass: powerpointIcon,
    style: { width: "36px", color: "#d6572e" },
  },
  ".txt": {
    iconClass: textIcon,
    style: { width: "36px", color: "#949494" },
  },
  ".mp4": {
    iconClass: videoIcon,
    style: { width: "36px", color: "#222" },
  },
  ".avi": {
    iconClass: videoIcon,
    style: { width: "36px", color: "#222" },
  },
  ".mkv": {
    iconClass: videoIcon,
    style: { width: "36px", color: "#222" },
  },
  ".wmv": {
    iconClass: videoIcon,
    style: { width: "36px", color: "#222" },
  },
  default: {
    iconClass: defaultIcon,
    style: { width: "36px", color: "#1d3050" },
  },
};

export function handleSelectFileIcon(fileName: string) {
  if (!fileName) {
    return iconEvidenceConditions["default"];
  }

  const extension = fileName.split(".").pop();
  const icon = iconEvidenceConditions[`.${extension}`] || iconEvidenceConditions["default"];
  
  return icon;
}

