import { formatData } from "./../service/table-data";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps, LinkedTaskProps } from "@/service/table-data";
import { submitFormFilter } from "@/api/ts/dropdowns_config";

export type FormState = Ref<Record<string, string>>;
// this is our 'risco' app store
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  serverFilteredData: TableItemProps[];
  isFavoriteActive: boolean;
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
  currentSelectedPlan: Ref<TableItemProps | null>;
  selectedTaskRow: Ref<LinkedTaskProps | null>;
  formRiskState: FormState;
  filterState: Record<string, any> | null;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: [],
    filteredDataItems: [],
    serverFilteredData: [], // Base filtrada pelo servidor
    isFavoriteActive: false, // Novo estado para controlar o filtro de favoritos
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
    currentSelectedPlan: ref(null),
    selectedTaskRow: ref(null),
    formRiskState: ref({
      name: "",
      description: "",
      category: "",
      priority: "",
      evaluator: "",
      area: "",
      responsible: "",
    }) as FormState,
    filterState: null,
  }),
  actions: {
    setInitialTableItems(payload: any) {
      // Formata os dados com base na função utilitária existente
      const dataFormatted = formatData(payload).map((item) => ({
        ...item,
      }));

      // Atualiza o estado com os dados formatados e pré-processados
      this.filteredDataItems = [...dataFormatted];
      this.dataItems = [...dataFormatted];
      this.serverFilteredData = [...dataFormatted];
    },
    validateStatus(payload) {
      const validStatuses = [
        "identificado",
        "avaliado",
        "em tratamento",
        "tratado",
        "monitoramento",
      ];
      return validStatuses.includes(payload.toLowerCase());
    },
    setTableCustomFilter(customFilters: any) {
      this.filterState = customFilters; // Salva o estado do filtro

      // Limpa os dados locais antes da nova requisição
      this.serverFilteredData = [];
      this.filteredDataItems = [];

      submitFormFilter(customFilters)
        .then((serverFilteredData) => {
          if (!serverFilteredData || serverFilteredData.length === 0) {
            console.warn("Nenhum dado retornado do servidor.");
            return;
          }

          // Formata os dados recebidos do servidor
          const formattedData = formatData(serverFilteredData);

          // Salva os dados no estado
          this.serverFilteredData = formattedData;

          // Aplica filtro adicional caso o status não seja "all"
          if (
            this.selectedStatus &&
            this.selectedStatus.toLowerCase() !== "all"
          ) {
            this.filteredDataItems = formattedData.filter(
              (item) =>
                item.state?.toLowerCase() === this.selectedStatus.toLowerCase()
            );
          } else {
            // Caso contrário, exibe todos os dados retornados
            this.filteredDataItems = [...formattedData];
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar dados do servidor:", error);
        });
    },

    applyLocalFilters() {
      if (!this.serverFilteredData.length) {
        console.warn("Nenhum dado disponível para filtrar.");
        this.filteredDataItems = [];
        return;
      }

      // Caso o filtro de favoritos esteja ativo
      if (this.isFavoriteActive) {
        this.filteredDataItems = this.serverFilteredData.filter((item) =>
          this.favoriteItems.some((fav) => fav.Id === item.Id)
        );
        return; // Prioriza favoritos e não aplica outros filtros
      }

      // Filtro principal: risco
      let filteredByRisk = this.serverFilteredData;
      if (this.selectedRisk && this.selectedRisk.toLowerCase() !== "all") {
        filteredByRisk = this.serverFilteredData.filter(
          (item) =>
            item.atual_risk?.toLowerCase() === this.selectedRisk.toLowerCase()
        );
      }

      // Filtro secundário: status
      if (this.selectedStatus && this.selectedStatus.toLowerCase() !== "all") {
        this.filteredDataItems = filteredByRisk.filter(
          (item) =>
            item.state?.toLowerCase() === this.selectedStatus.toLowerCase()
        );
      } else {
        // Caso o status seja "all", mantém apenas o filtro de risco
        this.filteredDataItems = [...filteredByRisk];
      }

      // Caso nenhum filtro específico esteja ativo, mantém todos os dados
      if (
        (!this.selectedStatus || this.selectedStatus.toLowerCase() === "all") &&
        (!this.selectedRisk || this.selectedRisk.toLowerCase() === "all")
      ) {
        this.filteredDataItems = [...this.serverFilteredData];
      }
    },

    setTableFilter(status?: string | null, risk?: string | null) {
      // Atualiza o status
      if (status !== undefined) {
        this.selectedStatus = status;
      }

      // Atualiza o risco somente se for diferente do atual
      if (risk !== undefined && risk !== this.selectedRisk) {
        this.selectedRisk = risk;
      }

      // Aplica os filtros locais
      this.applyLocalFilters();
    },

    resetCustomFilter() {
      this.filteredDataItems = [...this.dataItems];
      this.serverFilteredData = [...this.dataItems];
      this.selectedStatus = null;
      this.selectedRisk = null;
      this.isFavoriteActive = false;
      this.filterState = [];
    },
    addRowToFavorite(payload: TableItemProps) {
      const alreadyFavorited = this.favoriteItems.some(
        (item) => item.Id === payload.Id
      );
      if (!alreadyFavorited) {
        this.favoriteItems.push(payload);
      }
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.Id === payload.Id
      );
      if (index !== -1) {
        this.favoriteItems.splice(index, 1);
      }
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    setCurrentSelectedPlan(payload: TableItemProps | null) {
      if (this.dataItems.length === 0) return;
      this.currentSelectedPlan = payload;
    },
    setSelectedTaskRow(payload: LinkedTaskProps | null) {
      this.selectedTaskRow = payload;
    },
  },
});
function formatStatus(status: any) {
  throw new Error("Function not implemented.");
}
