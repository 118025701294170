<template>
    <div class="w-[50%] overflow-y-scroll border rounded-md py-2 px-6">   
        <div v-if="loading">
            <div class="flex" v-for="i in 6" :key="i">
                <div class="w-64 min-w-[58px] max-w-[100px]">
                    <component :is="Skeleton" :class="`!h-[16px] mt-2 !w-[80px]`" />
                </div>
                <div class="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:bg-gray-300 before:rounded-full after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[15px] after:w-0 after:h-auto after:border-l-2 after:border-gray-300 after:rounded-full">
                </div>
                <div class="p-2.5 self-center ltr:ml-2.5 ml-2.5">
                    <component :is="Skeleton" :class="`!h-[16px] !w-[150px] mb-[6px]`"/>
                    <component :is="Skeleton" :class="`!h-[12px] !w-[50px]`" />
                </div>
            </div>
        </div>
        <div class="flex" v-for="event in events?.value" :key="event.id" :style="`--color: var(--${event.color})`" v-else>
            <p class="text-[#3b3f5c] w-64 dark:text-white-light min-w-[58px] max-w-[100px] text-sm py-2.5">
                {{ event.created_at }}</p>
            <div
                class="relative before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-[var(--color)] before:rounded-full after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[15px] after:w-0 after:h-auto after:border-l-2 after:border-[var(--color)] after:rounded-full">
            </div>
            <div class="p-2.5 self-center ltr:ml-2.5 ml-2.5">
                <p class="text-[#3b3f5c] dark:text-white-light max-w-64 text-[13px] m-0">{{ event.title }}</p>
                <p class="text-white-dark text-xs self-center min-w-[100px] max-w-[100px]">{{ event.time }}h</p>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { ref } from 'vue';
import Skeleton from 'primevue/skeleton';

type Event = {
    id: number;
    time: string;
    title: string;
    created_at: string;
    color: string;
}

export default {
    name: 'SimpleTimeline',
    setup() {
        return { Skeleton };
    },
    props: {
        data: {
            type: Array as () => Event[],
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            events: ref([]),
            isLoading: false
        }
    },
    mounted(){
        this.loadData(this.data)
    },
    methods: {
        async loadData(row){
            if(!row) return;
            this.events.value = row
        },
    },
    watch: {
        data(newValue){
            this.loadData(newValue)
        }
    }
};



</script>

<style></style>