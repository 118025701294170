<template>
  <div class="relative">
    <div ref="chatContainer" :class="'overflow-y-scroll '+ this.class">
      <div class="space-y-5 sm:pb-0 pb-[68px] sm:min-h-[300px] min-h-[400px]">
        <div v-if="loadingStatus" class="flex justify-content-center items-center">
          <component :is="ProgressSpinner" class="custom-spinner" animationDuration=".5s" style="width: 44px; height: 44px"
            strokeWidth="4" />
        </div>
        <div v-if="!loadingStatus && chatMessagesByDay && chatMessagesByDay.length === 0"
          class="flex justify-center items-center min-h-[calc(99vh-506px)]">
          <img :src="emptyChat" class="object-cover" width="300" height="300" />
        </div>

        <!-- Mensagens organizadas por dia -->
        <div v-if="!loadingStatus && chatMessagesByDay"
          v-for="{ date, messages } in chatMessagesByDay" :key="`date-${date}`" class="p-4">
          <div class="block m-6 mt-0">
            <h4 class="text-xs text-center border-b border-[#f4f4f4] dark:border-gray-800 relative">
              <span class="relative top-2 px-3 bg-white dark:bg-[#0e1726]">{{ date }}</span>
            </h4>
          </div>

          <!-- Mensagens do usuário -->
          <div v-for="(message, index) in messages" :key="`message-${index}`" class="flex items-start gap-3 mt-1" 
            :class="{
              'justify-end': user.id === message.id_user_from_message,
              'justify-start': user.id === message.id_user_to_message,
            }">
            <div class="flex-none"
              :class="{'order-2': user.id === message.id_user_from_message}">
              <img :src="getAvatarUrl(message)" class="rounded-full h-10 w-10 object-cover" />
            </div>
            <div class="space-y-[8px]">
              <div class="flex gap-3" :class="message.id_user_from_message === user.id ? 'justify-end' : 'justify-start'">
                <div class="dark:bg-gray-800 p-4 py-2 max-w-xs rounded-md bg-black/10"
                  :class="message.id_user_from_message === user.id ? 'ltr:rounded-br-none rtl:rounded-bl-none !bg-primary-second text-white' : 'ltr:rounded-bl-none rtl:rounded-br-none'">
                  {{ message.message }}
                </div>
              </div>
              <div class="text-xs text-white-dark"
                :class="{
                  'text-right': user.id === message.id_user_from_message,
                  'text-left': user.id !== message.id_user_from_message,
                }">
                {{ getUserName(message) }} {{ message.date_time ? formatTime(message.date_time) : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- input de enviar mensagem -->
    <div class="p-4 bottom-2 left-0 w-full">
      <div class="sm:flex w-full space-x-3 items-center">
        <div class="relative flex-1">
          <component :is="iconInputChatButton"
            class="absolute left-4 top-1/2 -translate-y-1/2 text-primary-second hover:text-primary" />
          <component :is="InputText" class="form-input h-[36px] border text-dark-second font-normal sm:text-[12px] md:text-[13px] lg:text-[14px] rounded-full bg-[#f4f4f4] px-12 focus:outline-none py-2"
            placeholder="Digite uma mensagem" :disabled="!canSendMessage" v-model="textMessage"
            @keyup.enter.exact="handleSendMessage" unstyled />
          <button type="button"
            class="absolute right-4 top-1/2 -translate-y-1/2 text-primary-second hover:text-primary"
            @click="handleSendMessage" :disabled="!canSendMessage">
            <component :is="iconSend" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import emptyChat from "../assets/images/emptyChat.svg";
import userAvatar from "../assets/images/avatar.svg";
import iconInputChatButton from "./icon/icon-input-chat-button.vue";
import iconSend from "./icon/icon-send.vue";
import ProgressSpinner from 'primevue/progressspinner'; 
import InputText from 'primevue/inputtext';

type ChatMessage = {
  id_user_from_message: string;
  id_user_to_message: string;
  message: string;
  date_time: string;
  user1: {
    id: string;
    displayName: string;
    avatarUrl: string;
  };
  user2: {
    id: string;
    displayName: string;
    avatarUrl: string;
  };
};

type ChatMessagesByDay = {
  date: string; 
  messages: ChatMessage[]; 
}[];

export default defineComponent({
  emits: ['sendMessage'],
  name: 'Chat',
  props: {
    chatMessagesByDay: {
      type: Array as () => ChatMessagesByDay,
      required: true
    },
    loadingStatus: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    canSendMessage: {
      type: Boolean,
      required: true
    },
    class: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return {
      iconInputChatButton,
      iconSend,
      ProgressSpinner,
      InputText
    };
  },
  data() {
    return {
      textMessage: '',
      emptyChat,
    };
  },
  watch: {
    "chatMessagesByDay"(newValue) {
        this.scrollToBottom();
    },
  },
  methods: {
    getAvatarUrl(message) {
      const user = message.user1.id === message.id_user_from_message ? message.user1 : message.user2;
      return user.avatarUrl || userAvatar;
    },
    getUserName(message) {
      const user = message.user1.id === message.id_user_from_message ? message.user1 : message.user2;
      return user.displayName || "Unknown";
    },
    formatTime(dateTime) {
      return new Date(dateTime).toLocaleTimeString();
    },
    // Método que rola o scroll para a última mensagem
    scrollToBottom() {
      nextTick(() => {
        const container = this.$refs.chatContainer as HTMLElement;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    handleSendMessage() {
      // Use nextTick para garantir que o v-model seja atualizado antes de emitir o evento
      this.$nextTick(() => {
        if (this.textMessage.trim() !== '') { // Verifica se a mensagem não está vazia
          this.$emit('sendMessage', this.textMessage);
          this.textMessage = ''; // Limpa o campo de texto após o envio
        }
      });
    },
  }
});
</script>
